class SmsUserCreate
  constructor: () ->
  
  @loadUserData: () ->
    token = $("input[name='_token']").val()
    login = $("input[name='loginname']").val()
    $.ajax
      url: BASE_URL + '/sms/users/sms/user-data'
      method: 'POST'
      data:
        loginname: login
        _token: token
      success: (data) ->
        jsonData = JSON.parse(data)
        if(jsonData.name && jsonData.email)
          $("#name").val(jsonData.name)
          $("#email").val(jsonData.email)
          $("#loginname").val(jsonData.loginname)
        else
          toastr.error(jsonData)
    return
	
  @addLdapAction: () =>
    $('#loginname').on 'change', () ->
      SmsUserCreate.loadUserData()
    return  
	
  @hideTerritorialReference: (element) =>
    if($(element).val() == '1' || $(element).val() == '7')
      $('#div-territorial-reference').hide()
      $('#territorial_reference_id').val(null)
      $('#territorial_reference_id').prop('disabled',true)
    else
      $('#territorial_reference_id').val()
      $('#div-territorial-reference').show()
      $('#territorial_reference_id').prop('disabled',false)

window.SmsUserCreate = SmsUserCreate;
