class SmsUserIndex
  constructor: () ->
  
  @init: (type) ->
    cpfMask = {
      mask: ["999.999.999-99"],
      keepStatic: true,
      clearIncomplete: true
    };
    phoneMask = {"mask": ["(99) 9999-9999", "(99) 99999-9999"], "clearIncomplete": true};
    emailMask = {"alias" : "email", "clearIncomplete": true};
    jQueryExpCpf = "input[id*='cpf']";
    jQueryExpEmail = "input[id*='contact_email']";
    jQueryExpPhone = "input[id*='phone']";
    $(jQueryExpCpf).inputmask(cpfMask);
    $(jQueryExpEmail).inputmask(emailMask);
    $(jQueryExpPhone).inputmask(phoneMask);
    InstitutionUserIndex.addProfileButtonAction()
    return
	
  @buildTable: (dataset) =>
    $('#users-table').DataTable( {
      data: dataset
      columns: [
        { title: "Login rede PMPA"
        render: (data, type, row) ->
          if SmsUserIndex.hasPermission != undefined
            output = '<a href="' + data.href + '">' + data.label + '</a>'
          else
            output = '<label>' + data.label + '</label>'
          return output
        }
        { title: "Nome completo" }
        { title: "E-mail" }
        { title: "Perfil" }
        { title: "Referência territorial" }
      ],
      pageLength: 50,
      language: LocalConfiguration.getDatatablesTranslation()
    })
    return
	
  @addProfileButtonAction: () =>
    $('#add-profile').on 'click', () ->
      last = $('.profile-form').last()
      newNode = last.clone()
      index = parseInt(last.attr('index')) + 1
      newNode.attr('index',index)
      newNode.attr('id','profile-form-' + index)
      select = newNode.find('select')
      select.attr('name','educational_institution_member_profiles['+index+']')
      select.attr('id','educational_institution_member_profiles['+index+']')
      last.append('<br>')
      last.append(newNode)
    return  

window.SmsUserIndex = SmsUserIndex;